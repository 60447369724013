import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    mediaItems: [],
    activeMediaItemId: null,
    mediaItemsLoadingState: 'idle',
  },

  getters: {
    activeMediaItem(state, getters) {
      if (!state.activeMediaItemId) {
        return null;
      }

      const filtered = getters.mediaItems.filter((mediaItem) => {
        return mediaItem.id === state.activeMediaItemId;
      });

      return filtered.length ? filtered[0] : null;
    },

    mediaItems: (state) => state.mediaItems,
  },

  mutations: {
    setMediaItemsLoadingState(state, loadingState) {
      state.mediaItemsLoadingState = loadingState;
    },

    setMediaItems(state, mediaItems) {
      state.mediaItems = mediaItems;
    },

    setActiveMediaItemId(state, id) {
      state.activeMediaItemId = parseInt(id, 10);
    },
  },

  actions: {
    async loadMediaItems({ commit, dispatch }) {
      await dispatch('fetchCsrf');

      commit('setMediaItemsLoadingState', 'loading');

      return http.get(apiRoutes.mediaItems)
        .then(({ data }) => {
          commit('setMediaItems', data);
          commit('setMediaItemsLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setMediaItemsLoadingState', 'error');
          throw err;
        });
    },

    async updateMediaItem({ dispatch }, [mediaItemId, formData]) {
      await dispatch('fetchCsrf');

      const apiUrl = `${apiRoutes.mediaItems}/${mediaItemId}`;

      formData.append('_method', 'PUT');

      return http.post(apiUrl, formData)
        .then(() => {
          dispatch('loadMediaItems');
        })
        .catch((err) => {
          throw err;
        });
    },

    async deleteMediaItem({ dispatch }, mediaItemId) {
      await dispatch('fetchCsrf');

      const apiUrl = `${apiRoutes.mediaItems}/${mediaItemId}`;

      const formData = new FormData();
      formData.append('_method', 'DELETE');

      return http.post(apiUrl, formData)
        .then(() => {
          dispatch('loadMediaItems');
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
