<template>
  <button type="button" class="toast-update">
    Update!
  </button>
</template>

<style scoped>
.toast-update {
  font-size: 1rem;
  color: var(--purple);
  background-color: #fff;
  padding: .375rem 1rem;
  border-radius: .375rem;
  opacity: 1;
}
</style>
