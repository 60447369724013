import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    categories: [],
    activeCategory: null,
    categoriesLoadingState: 'idle',
    categoryLoadingState: 'idle',
  },

  getters: {
    activeCategory: (state) => state.activeCategory,
    categories: (state) => state.categories,
    categoryLoadingState: (state) => state.categoryLoadingState,
  },

  mutations: {
    setCategoriesLoadingState(state, loadingState) {
      state.categoriesLoadingState = loadingState;
    },

    setCategoryLoadingState(state, loadingState) {
      state.categoryLoadingState = loadingState;
    },

    setCategories(state, categories) {
      state.categories = categories;
    },

    setActiveCategory(state, data) {
      state.activeCategory = data;
    },

    setActiveCategoryBySlug(state, slug) {
      state.activeCategory = state.categories.find((category) => (
        category.translation.slug === slug
      ));
    },
  },

  actions: {
    async loadCategories({ commit }) {
      commit('setCategoriesLoadingState', 'loading');

      return http.get(apiRoutes.categories)
        .then(({ data }) => {
          commit('setCategories', data);
          commit('setCategoriesLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setCategoriesLoadingState', 'error');
          throw err;
        });
    },

    async loadCategory({ commit, state }, slug) {
      let route = apiRoutes.category;

      if (state.activeCategory && state.activeCategory.translation.slug === slug) {
        return Promise.resolve();
      }

      route = route.replace('{slug}', slug);

      commit('setCategoryLoadingState', 'loading');

      return http.get(route)
        .then(({ data }) => {
          commit('setActiveCategory', data);
          commit('setCategoryLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setCategoryLoadingState', 'error');
          throw err;
        });
    },
  },
};
