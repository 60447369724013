import axios from 'axios';
import config from './config';

const axiosInstance = axios.create({
  baseURL: config.baseApiUrl,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
