class TextInterpolate {
  constructor() {
    this.petType = '';
    this.adultPetType = '';
    this.petName = '';
  }

  setPetType(petType) {
    this.petType = petType === 'cat' ? 'kitten' : 'puppy';
    this.adultPetType = petType === 'cat' ? 'kat' : 'hond';
  }

  setPetName(petName) {
    this.petName = petName;
  }

  interpolate(text) {
    return text
      .replace(/\[name\]/g, this.petName)
      .replace(/\[type\]/g, this.petType)
      .replace(/\[adult-type\]/g, this.petType);
  }
}

export default TextInterpolate;
