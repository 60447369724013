<template>
  <div class="onboarding-layout" :class="`is-${theme.value}`">
    <div class="onboarding-layout__actions">
      <router-link
        class="onboarding-layout__actions-back"
        v-if="backRouteName"
        :to="{ name: backRouteName }"
      >
        <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
        <span class="sr-only">Terug naar vorige pagina</span>
      </router-link>

      <div class="onboarding-layout__progress" v-if="progress">
        <div :style="{ width: `${progress}%` }"></div>
      </div>
    </div>

    <div class="onboarding-layout__head">
      <div class="onboarding-layout__head-ground"></div>

      <img
        class="onboarding-layout__bush bush-1"
        :src="`/img/themes/default/onboarding-bush-1.svg`"
        alt="Illustratie van bosje"
        aria-hidden="true"
      >

      <img
        class="onboarding-layout__bush bush-2"
        :src="`/img/themes/${theme.value}/onboarding-bush-2.svg`"
        alt="Illustratie van bosje"
        aria-hidden="true"
      >

      <img
        class="onboarding-layout__cta"
        v-if="showCta && theme.value === 'christmas'"
        :src="`/img/themes/${theme.value}/onboarding-cta.svg`"
        alt="Call to action"
        aria-hidden="true"
      >

      <template v-if="!showHasMail">
        <img
          class="onboarding-layout__person is-male"
          v-if="layoutAvatar == 2"
          :src="`/img/themes/${theme.value}/onboarding-male.svg`"
          alt="Illustratie van mannelijke huisdier bezitter"
        />

        <img
          class="onboarding-layout__person is-female"
          v-if="layoutAvatar == 1"
          :src="`/img/themes/${theme.value}/onboarding-female.svg`"
          alt="Illustratie van vrouwelijke huisdier bezitter"
        />

        <div class="onboarding-layout__chat-bubble" v-if="petName">
          Hi {{ petName }}!
        </div>

        <div class="onboarding-layout__chat-bubble" v-if="name">
          Ik ben {{ name }}
        </div>

        <img
          class="onboarding-layout__pet is-dog"
          v-if="showDog"
          :src="`/img/themes/${theme.value}/onboarding-dog.svg`"
          alt="Illustratie van hond"
        />

        <img
          class="onboarding-layout__pet is-cat"
          v-if="showCat"
          :src="`/img/themes/${theme.value}/onboarding-cat.svg`"
          alt="Illustratie van kat"
        />

        <img
          class="onboarding-layout__gift"
          v-if="theme.value === 'christmas'"
          :src="`/img/themes/${theme.value}/onboarding-gift.svg`"
          alt="Illustratie van cadeau"
        />
      </template>

      <template v-if="showHasMail">
        <!-- eslint-disable -->
        <img
          class="onboarding-layout__hat"
          :class="{ 'is-cat': showCat }"
          v-if="theme.value === 'christmas'"
          :src="`/img/themes/${theme.value}/hat.svg`"
          alt="Illustratie van kerstmuts"
        />

        <svg v-if="showCat" class="onboarding-layout__mail onboarding-layout__mail--cat" xmlns="http://www.w3.org/2000/svg" width="180" height="124" viewBox="0 0 180 123.23">
          <title>Illustratie van een kat met een laptop</title>
          <g class="onboarding-layout__mail-bubble" id="bubble">
            <path d="M159.84 45.68l-13.56-3.2L130 52.75 135.64 40a12.68 12.68 0 01-9.43-15.27l3.09-13.12a12.7 12.7 0 0115.28-9.44l24.2 5.71a12.7 12.7 0 019.44 15.27l-3.1 13.13a12.71 12.71 0 01-15.28 9.4z" fill="#009ee3"/>
            <g id="envelope">
              <path d="M163.94 38.27l-27.51-6.81a3.47 3.47 0 01-2.63-4.12l3.64-15a3.43 3.43 0 014.22-2.43l27.51 6.81a3.47 3.47 0 012.63 4.12l-3.65 15a3.42 3.42 0 01-4.21 2.43z" fill="#fff"/>
              <path d="M169.24 17.21l-.29-.48-13.25 7.84a3.9 3.9 0 01-5.2-1.23L141.82 10l-.47.31 7.22 11.08-14.68 6 .22.52 14.77-6.06 1.12 1.8a4.48 4.48 0 003.75 2 4.35 4.35 0 002.2-.59l1.44-.86 10 12.66.44-.35-10-12.6z" fill="#dce2f4"/>
            </g>
          </g>
          <path id="body" d="M103.38 101.26l5.46-6s4.16-25.06-1.71-32.54c-3.94-5-17.74-10.66-31.61-11.2-10.81-.41-30.62 6.5-33 17.85l-1.64 22.51L53 101.06z" fill="#af897d"/>
          <path class="onboarding-layout__mail-tail-cat" id="tail" d="M2.3 73s8.76-6.48 21.78 2.88 13.21 24.86 12.23 26-2.39-1.72-2.31-1.88S22.81 78.7 9 79.67C4.52 81.43-2.54 77.7 2.3 73z" fill="#c6a89d"/>
          <path d="M42.4 68.7s-4.32 19 1.33 18.29 4.77-13.81 4.77-13.81z" fill="#af897d"/>
          <path d="M110.92 10.28c-5.62-2.15-24.33 4.92-24.33 4.92l.12.13a52.93 52.93 0 00-9-1.73 24.22 24.22 0 00-10.44 1.51c-5-1.8-17.73-6-22.21-4.36-5.55 2.07.78 20.52 5.93 23C48.18 46.5 49 64.79 76.66 65.53c32.41.87 28.05-31.33 28.05-31.33l-.18-.86c4.47-1.28 12.01-20.9 6.39-23.06z" fill="#c6a89d"/>
          <path d="M68.46 27.79c2.79.31 4.71 3.59 4.8 3.75l-.87 1.23s-2.11-3.59-4.85-3.24c-1.58.2-3.1 1.67-4.52 4.37l-.83-1c1.65-3.13 3.46-4.84 5.38-5.09a4.41 4.41 0 01.89-.02zM85.88 27.79c-2.78.31-4.7 3.59-4.79 3.75l.91 1.23s2.11-3.59 4.86-3.24c1.58.2 3.1 1.67 4.52 4.37l.83-1c-1.65-3.13-3.46-4.84-5.39-5.09a4.41 4.41 0 00-.94-.02zM84.88 44.22c-.74 2.49-1.7 3.92-2.85 4.22s-2.53-.39-4.26-2l-.14-3.14c1.63-.28 3.23-1.61 3.23-3.21a2.57 2.57 0 00-2.57-2.57h-2.53a2.57 2.57 0 00-2.57 2.57c0 1.56 1.53 2.87 3.11 3.19l.15 3.26c-1.12 1.57-2.53 2.28-4 2a5.05 5.05 0 01-3.69-4l-1.29.31a6.37 6.37 0 004.74 5.05 4.78 4.78 0 00.84.08 5.41 5.41 0 004.17-2.21c2 1.75 3.65 2.4 5.15 2 1.67-.44 2.9-2.12 3.79-5.14z" fill="#1a1c3a"/>
          <g id="laptop">
            <rect x="25.43" y="61.21" width="101.39" height="60.29" rx="10" fill="#1a1c3a"/>
            <path d="M85.73 98.27a3.78 3.78 0 01-3.28 4.18 3.14 3.14 0 01-.81 0c-2.07-.1-3.52-.93-5-.93h-.06c-1.47 0-2.92.86-5 1a3.67 3.67 0 01-4.09-3.24 4.31 4.31 0 010-.88c.13-2.89 2.69-3.1 4.88-6.51a4.89 4.89 0 014.22-2.75h.06a4.91 4.91 0 014.21 2.75c2.14 3.4 4.75 3.49 4.87 6.38zM72.87 87.82a3.61 3.61 0 003-4.1.5.5 0 000-.12 3.83 3.83 0 00-3.68-3.79 3.61 3.61 0 00-3 4.09.68.68 0 000 .14 3.83 3.83 0 003.68 3.78zm7 0a4.17 4.17 0 004.5-3.15 3.45 3.45 0 00-1.62-4.51 2 2 0 00-.4-.16 4.17 4.17 0 00-4.5 3.15 3.45 3.45 0 001.62 4.51 3.12 3.12 0 00.39.15zm8.33-1.73a3.63 3.63 0 00-3.92 2.74 3 3 0 001.42 3.93l.34.14a3.63 3.63 0 003.92-2.75 3 3 0 00-1.42-3.93 2.37 2.37 0 00-.34-.13zm-18.51 2.78a3.63 3.63 0 00-3.92-2.74 3 3 0 00-1.88 3.72 2.37 2.37 0 00.13.34 3.61 3.61 0 003.92 2.74 3 3 0 001.88-3.72c-.04-.11-.08-.21-.13-.34z" fill="#070923"/>
            <rect x="108.71" y="65.94" width="13.77" height="13.05" rx="6.45" fill="#070923"/>
            <path d="M118.63 68.57a1.78 1.78 0 11-1.78 1.78 1.78 1.78 0 011.78-1.78m0-.56a2.35 2.35 0 102.37 2.34 2.35 2.35 0 00-2.37-2.35zM113.28 73.08a1.78 1.78 0 11-1.78 1.78 1.78 1.78 0 011.78-1.78m0-.57a2.35 2.35 0 102.35 2.35 2.35 2.35 0 00-2.35-2.35z" fill="#232957"/>
          </g>
          <path d="M91.59 18.41s12.39-7.13 15.77-4.13-4.88 16.14-4.88 16.14-7.89-10.88-10.89-12.01zM62.38 18.41S50 11.28 46.61 14.28s4.88 16.14 4.88 16.14 7.88-10.88 10.89-12.01z" fill="#af897d"/>
          <g id="wiskers" fill="#af897d">
            <path transform="rotate(-7.44 99.307 38.12)" d="M89.54 37.88h19.59v.57H89.54z"/>
            <path transform="rotate(-82.57 99.33 44.67)" d="M99.05 34.87h.57v19.59h-.57z"/>
            <path transform="rotate(-82.5 54.87 38.24)" d="M54.53 28.37h.57v19.59h-.57z"/>
            <path transform="rotate(-7.44 54.786 44.647)" d="M45.02 44.39h19.59v.57H45.02z"/>
          </g>
        </svg>

        <svg v-if="showDog" class="onboarding-layout__mail" xmlns="http://www.w3.org/2000/svg" width="122.651" height="156.676" viewBox="0 0 122.65 156.68">
          <title>Illustratie van een puppy die een envelop in zijn bek heeft</title>
          <path id="poot-links" fill="#e5b28f" d="M96.29 78.46c7.88-.58 13.23 17.75 9.23 32.16s-8.33 44-22.22 45.9-13.72-13.28-11-19.3c-.03 0 8.16-57.59 23.99-58.76z"/>
          <path class="onboarding-layout__mail-tail" id="staart" fill="#e5b28f" d="M26.58 67.53s-15.67-5.45-15.07-29.85S31.45-.24 33.75 0s-.15 4.5-.48 4.53-17.1 32.34-4.08 48.83c6.07 4.15 7.44 16.23-2.61 14.17z"/>
          <path id="achterpoot-rechts" fill="#d69970" d="M8.36 103.55s-6.58 28.89 2 27.83 7.25-21 7.25-21z"/>
          <path id="lichaam" fill="#d69970" d="M24.4 125S4.56 105.28 4.56 94.79s-1.18-28.29 16.7-30.2 22.59 27.72 22.59 27.72l10.4 23.93z"/>
          <path id="poot-rechts" fill="#e5b28f" d="M27.2 83.5c-9.22-1.76-16.13 21.19-13 35.22s18.52 33.67 32.14 33.67c19.6 0 7.41-13.75 1.2-17.57s-19-19-15.64-23.26c7.87-9.83 2.29-26.72-4.7-28.06z"/>
          <path id="kop" fill="#e5b28f" d="M27.91 32.19S46.16 14 62.35 15.84s43.7 10.7 40.89 36-15.56 36.42-21.88 40.82-9.09 9.06-21 9.06c-13.15 0-16.64-4.72-25.09-14.24-4.6-5.14-23.74-35.79-7.36-55.29z"/>
          <g id="envelop">
            <g id="Group_165" data-name="Group 165">
              <path id="Path_203" data-name="Path 203" d="M97.68 141.39l-69 1.3a8.44 8.44 0 01-8.78-8L19 97a8.36 8.36 0 018.35-8.37h.05l69-1.3a8.46 8.46 0 018.78 8l.89 37.62a8.36 8.36 0 01-8.35 8.36z" fill="#fff"/>
            </g>
            <g id="Group_166" data-name="Group 166">
              <path id="Path_204" data-name="Path 204" fill="#dce2f4" d="M63 118.49a10.76 10.76 0 01-7.22-2.74L27 89.86l.91-1 28.8 25.89a9.5 9.5 0 0013-.38l26.2-26.79 1 1-26.26 26.74a10.68 10.68 0 01-7.65 3.17z"/>
            </g>
            <g id="Group_167" data-name="Group 167">
              <path id="Rectangle_41" data-name="Rectangle 41" fill="#dce2f4" transform="rotate(-37.31 36.288 123.366)" d="M16.57 122.7h39.45v1.37H16.57z"/>
            </g>
            <g id="Group_168" data-name="Group 168">
              <path id="Rectangle_42" data-name="Rectangle 42" fill="#dce2f4" transform="rotate(-53.3 89.362 123.372)" d="M88.69 103.39h1.37v39.99h-1.37z"/>
            </g>
          </g>
          <g id="bek">
            <path id="Path_205" data-name="Path 205" fill="#e5b28f" d="M43.89 87.73s2.53 8.86 8.62 8.86a10 10 0 008.66-4.86s5.06 4.77 9.25 4.77 9.41-9.66 9.41-9.66"/>
            <path id="Path_206" data-name="Path 206" fill="#1a1c3a" d="M52.52 97.67c-6.84 0-9.55-9.25-9.67-9.65l2.08-.59c0 .13 2.4 8.08 7.59 8.08a8.85 8.85 0 007.7-4.29l.66-1.22 1 1c.05 0 4.8 4.48 8.51 4.48 2.58 0 6.56-5.58 8.47-9.1l1.9 1a42.7 42.7 0 01-3.23 5c-2.65 3.49-5 5.26-7.14 5.26-3.46 0-7.29-2.79-9-4.24a11 11 0 01-8.87 4.27zm-8.63-9.94l1-.3z"/>
          </g>
          <path id="oor-links" fill="#a77046" d="M93.87 31.65c4.91-12.75 37.87 37.21 26.36 45.72S80.23 67 93.87 31.65z"/>
          <path id="oor-rechts" fill="#a77046" d="M30.57 34C26.24 21-8.95 69.4 2.16 78.42S42.59 69.91 30.57 34z"/>
          <path id="oog-rechts" fill="#1a1c3a" d="M48.59 43.89c3.92.43 6.62 5 6.74 5.27l-1.21 1.74s-3-5.06-6.83-4.56c-2.22.28-4.36 2.35-6.36 6.15l-1.17-1.44c2.33-4.4 4.87-6.81 7.58-7.15a4.76 4.76 0 011.25-.01z"/>
          <path id="oog-links" fill="#1a1c3a" d="M73.09 43.89c-3.91.43-6.61 5-6.74 5.27l1.21 1.74s3-5.06 6.83-4.56c2.22.28 4.36 2.35 6.36 6.15l1.17-1.44c-2.32-4.4-4.87-6.81-7.57-7.15a4.83 4.83 0 00-1.26-.01z"/>
          <path id="neus" fill="#1a1c3a" d="M59.84 72.52h.06c-2.95 0-6.27-2.39-6.27-5.33A4.18 4.18 0 0157.81 63h4.12a4.18 4.18 0 014.18 4.18c0 2.95-3.33 5.34-6.27 5.34z"/>
        </svg>
        <!-- eslint-enable -->
      </template>
    </div>

    <div class="onboarding-layout__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['theme'],

  props: {
    backRouteName: {
      type: String,
    },

    petType: {
      type: String,
    },
  },

  computed: {
    layoutAvatar() {
      return this.$store.state.layout.layoutAvatar;
    },

    showCat() {
      return this.petType ? this.petType === 'cat' : this.$store.state.layout.showCat;
    },

    showDog() {
      return this.petType ? this.petType === 'dog' : this.$store.state.layout.showDog;
    },

    progress() {
      return this.$store.state.layout.progress;
    },

    showHasMail() {
      return this.$store.state.layout.showHasMail;
    },

    showCta() {
      return this.$store.state.layout.showCta;
    },

    petName() {
      return this.$store.state.layout.petName;
    },

    name() {
      return this.$store.state.layout.name;
    },
  },
};
</script>

<style>
.onboarding-layout {
  position: relative;
}

.onboarding-layout__head {
  padding-bottom: 78%;
  position: relative;
  overflow: hidden;
  background-size: contain;
  background-color: #fce3e3;
  background-image: url('/img/themes/default/onboarding-bg.svg');
  background-repeat: no-repeat;
  background-position: 50% 12%;
}

.onboarding-layout__head-ground {
  height: 32.5%;
  width: 100%;
  background-color: #8bbeb6;
  position: absolute;
  bottom: 0;
  left: 0;
}

.onboarding-layout__person {
  position: absolute;
  bottom: 28%;
  right: 50%;
  margin-left: 10rem;
}

.onboarding-layout__bush {
  position: absolute;
  bottom: 27%;
}

.onboarding-layout__bush.bush-1 {
  left: 0;
}

.onboarding-layout__bush.bush-2 {
  right: -1rem;
}

.onboarding-layout__pet {
  position: absolute;
  bottom: 26%;
  left: 50%;
  margin-left: 3rem;
}

.onboarding-layout__pet.is-dog {
  bottom: calc(26% - 1.5rem);
  margin-left: 5rem;
  z-index: 1;
}

.onboarding-layout__chat-bubble {
  background-color: #fff;
  position: absolute;
  top: calc(72% - 9.5rem);
  left: calc(50% - 1.5rem);
  max-width: calc(100% - 50%);
  padding: .75rem 1rem;
  font-weight: 700;
  color: var(--heading-color);
  border-radius: .75rem;
}

.onboarding-layout__chat-bubble:before {
  content: '';
  border: 6px solid #fff;
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  top: 1.625rem;
  right: 100%;
  transform: scaleY(.8);
}

.onboarding-layout__actions {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.onboarding-layout__actions-back {
  padding: 1rem;
  margin: -1rem 0 -1rem -1rem;
}

.onboarding-layout__progress {
  height: .5rem;
  background-color: #7a2766;
  flex: 1 1 auto;
  position: relative;
  border-radius: .25rem;
}

.onboarding-layout__progress div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #9ac426;
  border-radius: .25rem;
}

.onboarding-layout__content {
  background-color: #fff;
  border-radius: 2rem 2rem 0 0;
  margin-top: -2rem;
  padding: 1.5rem;
  position: relative;
}

.onboarding-layout__mail {
  position: absolute;
  bottom: 20%;
  left: 50%;
  margin-left: -4rem;
}

.onboarding-layout__mail--cat {
  width: 14rem;
  height: 10rem;
  margin-left: -7rem;
}

.onboarding-layout__mail-tail {
  transform-origin: 29px 63px;
  animation: tail-wag .8s infinite ease-in-out alternate;
}

.onboarding-layout__mail-tail-cat {
  transform-origin: 100% 100%;
  animation: cat-tail-wag 1.5s infinite ease-in-out alternate;
}

.onboarding-layout__mail-bubble {
  transform-origin: 5% 100%;
  animation: bubble-move 4s infinite ease-in-out;
}

.onboarding-layout__hat {
  left: 50%;
  bottom: 20%;
  z-index: 1;
  position: absolute;
  transform: translateX(-50%);
  margin-bottom: 8rem;
}

.onboarding-layout__hat.is-cat {
  width: 58px;
  margin-left: -1rem;
  margin-bottom: 7.8rem;
}

.onboarding-layout__cta {
  left: 50%;
  width: 6.1875rem;
  height: 6.1875rem;
  bottom: 55%;
  position: absolute;
  margin-left: 4.5rem;
  transform: translateX(-50%);
}

/* Christmas theme */
.is-christmas .onboarding-layout__bush.bush-2 {
  right: -3.125rem;
}

.is-christmas .onboarding-layout__head {
  background-image: url('/img/themes/christmas/onboarding-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 12%;
}

.is-christmas .onboarding-layout__person {
  bottom: 18%;
}

.is-christmas .onboarding-layout__gift {
  left: 50%;
  bottom: 17%;
  position: absolute;
  margin-left: .5rem;
  transform: translateX(-50%);
}

@keyframes tail-wag {
  0% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(12deg);
  }
}

@keyframes cat-tail-wag {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3deg);
  }
}

@keyframes bubble-move {
  0%,
  100% {
    transform: rotate(0deg) translateY(5px);
  }
  50% {
    transform: rotate(-3deg) translateY(5px);
  }
}

@media (max-width: 419px) {
  .onboarding-layout__bush.bush-1 {
    width: 3rem;
  }
}

@media (min-width: 640px) {
  .onboarding-layout__head {
    padding-bottom: 50%;
  }
}

@media (min-width: 640px) {
  .onboarding-layout__head {
    border-radius: 2rem 2rem 0 0;
  }

  .onboarding-layout__content {
    border-radius: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .onboarding-layout__bush.bush-2 {
    left: calc(50% + 12rem);
    right: auto;
  }

  .onboarding-layout__actions {
    padding: 1.5rem;
  }

  .onboarding-layout__actions-back {
    padding: 1.5rem;
    margin: -1.5rem 0 -1.5rem -1.5rem;
  }
}
</style>
