import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "feed" */ '../views/Feed.vue'),
    meta: { auth: true },
  },
  {
    path: '/terugblik',
    name: 'Flashback',
    component: () => import(/* webpackChunkName: "flashback" */ '../views/Flashback.vue'),
    meta: { auth: true, identifier: 'flashback' },
  },
  {
    path: '/snack/:slug',
    name: 'Snack',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    meta: { auth: false },
  },
  {
    path: '/milestone-groep/:id',
    name: 'MilestoneGroup',
    component: () => import(/* webpackChunkName: "milestone-group" */ '../views/Milestone/MilestoneGroup.vue'),
    meta: { auth: true },
  },
  {
    path: '/milestone/:slug',
    name: 'Milestone',
    component: () => import(/* webpackChunkName: "milestone" */ '../views/Milestone/Milestone.vue'),
    meta: { auth: true },
  },
  {
    path: '/milestone-complete/:slug',
    name: 'MilestoneComplete',
    component: () => import(/* webpackChunkName: "milestone-complete" */ '../views/Milestone/MilestoneComplete.vue'),
    meta: { auth: true },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: { auth: false },
  },
  {
    path: '/category/:slug',
    name: 'Category',
    component: () => import(/* webpackChunkName: "category" */ '../views/Category.vue'),
    meta: { auth: false },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/profile',
    name: 'SettingsProfile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Settings/Profile.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/password',
    name: 'SettingsPassword',
    component: () => import(/* webpackChunkName: "password" */ '../views/Settings/Password.vue'),
    meta: { auth: true },
  },
  {
    path: '/settings/notifications',
    name: 'SettingsNotifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Settings/Notifications.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet',
    name: 'Pet',
    component: () => import(/* webpackChunkName: "pet" */ '../views/Pet.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/profile',
    name: 'PetProfile',
    component: () => import(/* webpackChunkName: "pet-profile" */ '../views/Pet/Profile.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/weight',
    name: 'PetWeight',
    component: () => import(/* webpackChunkName: "pet-weight" */ '../views/Pet/PetWeight.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/weight/:id',
    name: 'PetWeightDetail',
    component: () => import(/* webpackChunkName: "pet-weight" */ '../views/Pet/PetWeight.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/height',
    name: 'PetHeight',
    component: () => import(/* webpackChunkName: "pet-height" */ '../views/Pet/PetHeight.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/height/:id',
    name: 'PetHeightDetail',
    component: () => import(/* webpackChunkName: "pet-height" */ '../views/Pet/PetHeight.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/note',
    name: 'PetNote',
    component: () => import(/* webpackChunkName: "pet-note" */ '../views/Pet/PetNote.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/note/:id',
    name: 'PetNoteDetail',
    component: () => import(/* webpackChunkName: "pet-note" */ '../views/Pet/PetNote.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/checklist',
    name: 'PetChecklist',
    component: () => import(/* webpackChunkName: "pet-note" */ '../views/Pet/PetChecklist.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/checklist/:id',
    name: 'PetChecklistDetail',
    component: () => import(/* webpackChunkName: "pet-note" */ '../views/Pet/PetChecklist.vue'),
    meta: { auth: true },
  },
  {
    path: '/pet/delete-profile',
    name: 'PetDeleteProfile',
    component: () => import(/* webpackChunkName: "delete-pet-profile" */ '../views/Pet/DeleteProfile.vue'),
    meta: { auth: true },
  },
  {
    path: '/password/reset/:token',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../views/Auth/PasswordReset.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/password/reset',
    name: 'RequestPasswordReset',
    component: () => import(/* webpackChunkName: "request-password-reset" */ '../views/Auth/RequestPasswordReset.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/type',
    name: 'OnboardingPetType',
    component: () => import(/* webpackChunkName: "onboarding-pet-type" */ '../views/Onboarding/PetType.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/pet-name',
    name: 'OnboardingPetName',
    component: () => import(/* webpackChunkName: "onboarding-pet-name" */ '../views/Onboarding/PetName.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/birthdate',
    name: 'OnboardingPetBirthdate',
    component: () => import(/* webpackChunkName: "onboarding-pet-birthdate" */ '../views/Onboarding/PetBirthdate.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/name',
    name: 'OnboardingName',
    component: () => import(/* webpackChunkName: "onboarding-name" */ '../views/Onboarding/Name.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/avatar',
    name: 'OnboardingAvatar',
    component: () => import(/* webpackChunkName: "onboarding-avatar" */ '../views/Onboarding/Avatar.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/email',
    name: 'OnboardingEmail',
    component: () => import(/* webpackChunkName: "onboarding-email" */ '../views/Onboarding/Email.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/onboarding/complete',
    name: 'OnboardingComplete',
    component: () => import(/* webpackChunkName: "onboarding-complete" */ '../views/Onboarding/Complete.vue'),
    meta: { auth: false, onboarding: true },
  },
  {
    path: '/over-ons',
    name: 'About',
    component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    meta: { auth: false, identifier: 'about' },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    meta: { auth: false, identifier: 'privacy' },
  },
  {
    path: '/algemene-voorwaarden',
    name: 'Agreement',
    component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    meta: { auth: false, identifier: 'agreement' },
  },
  {
    path: '/photo/:id',
    name: 'Photo',
    component: () => import(/* webpackChunkName: "photo" */ '../views/Photo.vue'),
    meta: { auth: false },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    meta: { auth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
