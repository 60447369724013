import { format } from 'date-fns';
import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    petType: null,
    petName: null,
    petBirthdate: null,
    email: null,
    agreement: false,
    firstName: null,
    lastName: null,
    avatar: null,

    registerLoadingState: 'idle',
  },

  getters: {
    registerLoadingState: (state) => state.registerLoadingState,
    onboardingSteps: (state, rootState) => (rootState.user ? rootState.user.onboarding_steps : []),
    hasOnboardingSteps: (state, getters) => getters.onboardingSteps.length,
  },

  mutations: {
    setPetType(state, petType) {
      state.petType = petType;
    },

    setPetName(state, petName) {
      state.petName = petName;
    },

    setPetBirthdate(state, petBirthdate) {
      state.petBirthdate = petBirthdate;
    },

    setEmail(state, value) {
      state.email = value;
    },

    setAgreement(state, value) {
      state.agreement = value;
    },

    setFirstName(state, value) {
      state.firstName = value;
    },

    setLastName(state, value) {
      state.lastName = value;
    },

    setAvatar(state, value) {
      state.avatar = value;
    },

    setRegisterLoadingState(state, value) {
      state.registerLoadingState = value;
    },
  },

  actions: {
    async register({ commit, state }) {
      commit('setRegisterLoadingState', 'loading');

      return http
        .post(apiRoutes.register, {
          email: state.email,
          agreement: state.agreement,
          first_name: state.firstName,
          last_name: state.lastName,
          avatar: state.avatar,
          pet_name: state.petName,
          pet_type: state.petType,
          pet_birthdate: state.petBirthdate ? format(state.petBirthdate, 'yyyy-MM-dd') : null,
        })
        .then((res) => {
          commit('setRegisterLoadingState', 'loaded');
          return res;
        })
        .catch((err) => {
          commit('setRegisterLoadingState', 'error');
          throw err;
        });
    },
  },
};
