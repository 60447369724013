// import config from '../config';

const defaultState = {
  layoutAvatar: 1,
  showDog: true,
  showCat: true,
  showHasMail: false,
  showCta: true,
  petName: null,
  name: null,
  progress: null,
};

export default {
  state: {
    ...defaultState,
  },

  mutations: {
    setLayoutAvatar(state, value) {
      state.layoutAvatar = value;
    },

    setShowDog(state, value) {
      state.showDog = value;
    },

    setShowCat(state, value) {
      state.showCat = value;
    },

    setPetName(state, value) {
      state.name = null;
      state.petName = value;
    },

    setName(state, value) {
      state.petName = null;
      state.name = value;
    },

    setShowMail(state, value) {
      state.showHasMail = value;
    },

    setProgress(state, value) {
      state.progress = value;
    },

    resetLayout(state, values = {}) {
      const newState = { ...defaultState, ...values };

      Object.keys(newState).forEach((key) => {
        const value = newState[key];

        if (typeof state[key] !== 'undefined') {
          state[key] = value;
        }
      });
    },
  },
};
