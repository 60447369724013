import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    profileLoadingState: 'idle',
  },

  getters: {
    profileLoadingState: (state) => state.profileLoadingState,
  },

  mutations: {
    setProfileLoadingState(state, loadingState) {
      state.profileLoadingState = loadingState;
    },
  },

  actions: {
    async storeProfileForm({ commit, dispatch }, formData) {
      await dispatch('fetchCsrf');

      commit('setProfileLoadingState', 'loading');

      formData.append('_method', 'PUT');

      return http.post(apiRoutes.updateUser, formData)
        .then(dispatch('fetchUser'))
        .then(() => {
          commit('setProfileLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setProfileLoadingState', 'error');
          throw err;
        });
    },
  },
};
