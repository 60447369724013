export default () => {
  window.OneSignal = window.OneSignal || [];

  const { OneSignal } = window;

  OneSignal.push(() => {
    OneSignal.init({
      appId: process.env.VUE_APP_ONESIGNAL,
      allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
    });
  });
};
