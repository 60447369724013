import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    notificationStoreLoadingState: 'idle',
  },

  getters: {
    notificationStoreLoadingState: (state) => state.notificationStoreLoadingState,
  },

  mutations: {
    setNotificationStoreLoadingState(state, loadingState) {
      state.notificationStoreLoadingState = loadingState;
    },
  },

  actions: {
    async putNotificationsForm({ commit, dispatch }, formData) {
      await dispatch('fetchCsrf');

      commit('setNotificationStoreLoadingState', 'loading');

      formData.append('_method', 'PUT');

      return http.post(apiRoutes.notifications, formData)
        .then(dispatch('fetchUser'))
        .then(() => {
          commit('setNotificationStoreLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setNotificationStoreLoadingState', 'error');
          throw err;
        });
    },
  },
};
