let baseDomain = process.env.VUE_APP_BACKEND_API;

// Overwrite base domain for staging environment.
if (window.location.href.indexOf('app-beta.') >= 0) {
  baseDomain = 'https://app-beta.kwispelcoach.com';
}

export default {
  environment: process.env.NODE_ENV,

  baseApiDomain: baseDomain,

  baseApiUrl: `${baseDomain}/api`,

  apiRoutes: {
    // Auth
    login: '/login',
    logout: '/logout',
    loginCheck: '/login/check',
    register: '/register',
    passwordReset: '/password/reset',
    requestPasswordReset: '/password/request',
    sanctumCsrf: '/sanctum/csrf-cookie',

    // User
    user: '/user',
    updateUser: '/user',
    deleteUser: '/user',
    updatePassword: '/password',

    // Pet
    updatePet: '/pet',

    // Pet weight
    showPetWeight: '/pet-weight/{id}',
    storePetWeight: '/pet-weight',
    destroyPetWeight: '/pet-weight/{id}',

    // Pet height
    showPetHeight: '/pet-height/{id}',
    storePetHeight: '/pet-height',
    destroyPetHeight: '/pet-height/{id}',

    // Pet note
    showPetNote: '/pet-note/{id}',
    storePetNote: '/pet-note',
    updatePetNote: '/pet-note/{id}',
    destroyPetNote: '/pet-note/{id}',

    // Pet checklist
    showPetChecklist: '/pet-checklist/{id}',
    storePetChecklist: '/pet-checklist',
    updatePetChecklist: '/pet-checklist/{id}',
    destroyPetChecklist: '/pet-checklist/{id}',

    // Articles
    article: '/article/{slug}',

    // Competition
    storeCompetition: '/competition',

    // Categories
    categories: '/article-category',
    category: '/article-category/{slug}',

    // Media
    imageUpload: '/image-upload',
    mediaItems: '/media-item',

    // Milestones
    milestones: '/milestone',
    markMilestoneComplete: '/mark-milestone-complete',
    markMilestoneSkipped: '/mark-milestone-skipped',
    updateMilestoneComlete: '/update-milestone-complete',
    updateMilestoneChecklist: '/update-milestone-checklist',

    // Settings
    notifications: '/notifications',
    address: '/address',

    // Theme
    theme: '/theme',

    // Other
    search: '/search',
    page: '/page/{identifier}',
    showTimeline: '/timeline',
  },

  sentryDsn: process.env.VUE_APP_SENTRY_DSN,
};
