import { createStore } from 'vuex';
import auth from './auth';
import onboarding from './onboarding';
import layout from './layout';
import articles from './articles';
import categories from './categories';
import search from './search';
import profile from './profile';
import pet from './pet';
import milestones from './milestones';
import page from './page';
import notification from './notification';
import mediaItems from './mediaItems';
import address from './address';
import timeline from './timeline';
import competition from './competition';
import theme from './theme';

export default createStore({
  state: {
    appIsLoading: false,
    flashMessage: {
      level: 'normal',
      message: null,
    },
  },

  getters: {
    flashMessage: (state) => state.flashMessage,
  },

  mutations: {
    setLoadingState(state, loadingState) {
      state.appIsLoading = loadingState;
    },

    setFlashMessage(state, payload) {
      const [message, level, duration] = payload;

      state.flashMessage.message = typeof message !== 'undefined' ? message : null;
      state.flashMessage.level = typeof level !== 'undefined' ? level : 'success';

      const timeout = typeof duration !== 'undefined' ? duration : 5000;

      setTimeout(() => {
        state.flashMessage.message = null;
        state.flashMessage.level = null;
      }, timeout);
    },
  },

  actions: {
    //
  },

  modules: {
    auth,
    onboarding,
    layout,
    articles,
    categories,
    search,
    profile,
    pet,
    milestones,
    page,
    notification,
    mediaItems,
    address,
    timeline,
    competition,
    theme,
  },
});
