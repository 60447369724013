import config from '../config';
import ApiHelper from '../helpers/ApiHelper';

const { apiRoutes } = config;

export default {
  state: {
    storeCompetitionLoadingState: 'idle',
  },

  getters: {
    storeCompetitionLoadingState: (state) => state.storeCompetitionLoadingState,
  },

  mutations: {
    setStoreCompetitionLoadingState(state, loadingState) {
      state.storeCompetitionLoadingState = loadingState;
    },
  },

  actions: {
    async storeCompetition(context, data) {
      return (new ApiHelper('post', apiRoutes.storeCompetition, data))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setStoreCompetitionLoadingState')
        .handle();
    },
  },
};
