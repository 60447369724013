import { useToast } from 'vue-toastification';
import config from '../config';
import http from '../http';
import ToastUpdateButton from '../components/ToastUpdateButton.vue';
import ApiHelper from '../helpers/ApiHelper';

const { apiRoutes, baseApiDomain } = config;

const toast = useToast();

export default {
  state: {
    user: null,
    userData: null,
    resetPasswordLoadingState: 'idle',
    requestResetPasswordLoadingState: 'idle',
    updatePasswordLoadingState: 'idle',
  },

  getters: {
    isLoggedIn: (state) => !!state.userData,
    user: (state) => state.user,
    activePet: (state, getters) => (getters.user ? getters.user.active_pet : null),
    activePetType: (state, getters) => (
      getters.activePet ? getters.activePet.pet_type_identifier : null
    ),
    activePetStage: (state, getters) => (
      getters.activePet ? getters.activePet.active_stage : null
    ),
    resetPasswordLoadingState: (state) => state.resetPasswordLoadingState,
    updatePasswordLoadingState: (state) => state.updatePasswordLoadingState,
    userFirstName: (state, getters) => (
      getters.user && getters.user.user ? getters.user.user.first_name : null
    ),
    petName: (state, getters) => (getters.activePet ? getters.activePet.name : null),
    activeSnacks: (state, getters) => (getters.user ? getters.user.active_snacks : null),
    activeMilestones: (state, getters) => (getters.user ? getters.user.active_milestones : null),
    weight: (state, getters) => (getters.user ? getters.user.weight : null),
    height: (state, getters) => (getters.user ? getters.user.height : null),

    birthdate(state, getters) {
      if (!getters.user) {
        return null;
      }

      return getters.user.user.birthdate;
    },

    birthdateParts(state, getters) {
      if (!getters.birthdate) {
        return null;
      }

      const [year, month, day] = getters.birthdate.split('-');

      return [parseInt(year, 10), parseInt(month, 10), parseInt(day, 10)];
    },

    petBirthdate(state, getters) {
      if (!getters.activePet) {
        return null;
      }

      return getters.activePet.birthdate;
    },

    petBirthdateParts(state, getters) {
      if (!getters.petBirthdate) {
        return null;
      }

      const [year, month, day] = getters.petBirthdate.split('-');

      return [parseInt(year, 10), parseInt(month, 10), parseInt(day, 10)];
    },

    /**
     * Progress in pet stage as float (0 to 1)
     *
     * @param state
     * @param getters
     * @returns {null|number}
     */
    activePetStageProgress(state, getters) {
      if (!getters.activePetStage) {
        return null;
      }

      const { day_start: dayStart, day_end: dayEnd } = getters.activePetStage;
      const { days_since_birth: daysSinceBirth } = getters.activePet;

      if (Number.isNaN(dayStart) || Number.isNaN(dayEnd) || Number.isNaN(daysSinceBirth)) {
        return null;
      }

      const stageDuration = dayEnd - dayStart;
      const daysIntoStage = daysSinceBirth - dayStart;

      if (Number.isNaN(daysIntoStage) || daysIntoStage < 0) {
        return null;
      }

      return daysIntoStage / stageDuration;
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },

    setResetPasswordLoadingState(state, loadingState) {
      state.resetPasswordLoadingState = loadingState;
    },

    setUpdatePasswordLoadingState(state, loadingState) {
      state.updatePasswordLoadingState = loadingState;
    },

    setRequestResetPasswordLoadingState(state, loadingState) {
      state.requestResetPasswordLoadingState = loadingState;
    },

    setRegisterLoadingState(state, loadingState) {
      state.registerLoadingState = loadingState;
    },
  },

  actions: {
    async login({ dispatch }, credentials) {
      await dispatch('fetchCsrf');

      return http
        .post(apiRoutes.login, credentials)
        .then(() => {
          dispatch('fetchUser');
        });
    },

    async loginCheck({ dispatch }, email) {
      await dispatch('fetchCsrf');

      return http
        .post(apiRoutes.loginCheck, {
          email,
        });
    },

    async logout() {
      return http
        .post(apiRoutes.logout)
        .then(() => {
          // window.location.reload();
        });
    },

    async resetPassword({ commit }, credentials) {
      commit('setResetPasswordLoadingState', 'loading');

      return http
        .post(apiRoutes.passwordReset, credentials)
        .then((res) => {
          commit('setResetPasswordLoadingState', 'loaded');
          return res;
        })
        .catch((err) => {
          commit('setResetPasswordLoadingState', 'error');
          throw err;
        });
    },

    async updatePassword(context, password) {
      return (new ApiHelper('post', apiRoutes.updatePassword, {
        _method: 'PUT',
        password,
      }))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setUpdatePasswordLoadingState')
        .handle();
    },

    async requestResetPassword(context, credentials) {
      return (new ApiHelper('post', apiRoutes.requestPasswordReset, credentials))
        .connectWithContext(context)
        .loadingStateCommit('setRequestResetPasswordLoadingState')
        .handle();
    },

    async removeUser(context, formData) {
      formData.append('_method', 'DELETE');

      return (new ApiHelper('post', apiRoutes.deleteUser, formData))
        .authenticated()
        .connectWithContext(context)
        .handle()
        .then(() => {
          context.dispatch('logout');
        });
    },

    fetchCsrf() {
      return http.get(apiRoutes.sanctumCsrf, { baseURL: baseApiDomain });
    },

    async fetchUser(context) {
      return (new ApiHelper('get', apiRoutes.user))
        .authenticated()
        .preventUserFetch()
        .connectWithContext(context)
        .handle()
        .then(({ data }) => {
          context.commit('setUser', data);
          context.dispatch('checkVersion');
        });
    },

    checkVersion({ getters }) {
      const currentVersionNumber = getters.user.version;
      const localVersionNumber = window.localStorage.getItem('version');

      // The first time we don't want to show an update bar. The user is already
      // on the latest and greatest version.
      if (!localVersionNumber) {
        window.localStorage.setItem('version', currentVersionNumber);
        return;
      }

      if (currentVersionNumber !== localVersionNumber) {
        toast.success('Er is een nieuwe versie van de Kwispelcoach!', {
          closeButton: ToastUpdateButton,
          timeout: 0,
          onClose: () => {
            window.location.reload(true);
          },
        });

        window.localStorage.setItem('version', currentVersionNumber);
      }
    },
  },
};
