import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    activePage: null,
    pageLoadingState: 'idle',
  },

  getters: {
    activePage: (state) => state.activePage,
  },

  mutations: {
    setPageLoadingState(state, loadingState) {
      state.pageLoadingState = loadingState;
    },

    setActivePage(state, data) {
      state.activePage = data;
    },
  },

  actions: {
    async loadPage({ commit }, identifier) {
      let route = apiRoutes.page;

      route = route.replace('{identifier}', identifier);

      commit('setPageLoadingState', 'loading');

      return http.get(route)
        .then(({ data }) => {
          commit('setActivePage', data);
          commit('setPageLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setPageLoadingState', 'error');
          throw err;
        });
    },
  },
};
