import config from '../config';
import ApiHelper from '../helpers/ApiHelper';

const { apiRoutes } = config;

export default {
  state: {
    petProfileLoadingState: 'idle',
    petWeightLoadingState: 'idle',
    petHeightLoadingState: 'idle',
    petNoteLoadingState: 'idle',
    petChecklistLoadingState: 'idle',
    petTab: 'milestones',
  },

  getters: {
    petProfileLoadingState: (state) => state.petProfileLoadingState,
    petWeightLoadingState: (state) => state.petWeightLoadingState,
    petHeightLoadingState: (state) => state.petHeightLoadingState,
    petNoteLoadingState: (state) => state.petNoteLoadingState,
    petChecklistLoadingState: (state) => state.petChecklistLoadingState,
    petTab: (state) => state.petTab,
  },

  mutations: {
    setPetProfileLoadingState(state, loadingState) {
      state.petProfileLoadingState = loadingState;
    },

    setPetWeightLoadingState(state, loadingState) {
      state.petWeightLoadingState = loadingState;
    },

    setPetHeightLoadingState(state, loadingState) {
      state.petHeightLoadingState = loadingState;
    },

    setPetNoteLoadingState(state, loadingState) {
      state.petNoteLoadingState = loadingState;
    },

    setPetChecklistLoadingState(state, loadingState) {
      state.petChecklistLoadingState = loadingState;
    },

    setPetTab(state, tab) {
      state.petTab = tab;
    },
  },

  actions: {
    async storePetProfileForm(context, formData) {
      formData.append('_method', 'PUT');

      return (new ApiHelper('post', apiRoutes.updatePet, formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetProfileLoadingState')
        .handle();
    },

    async getPetWeight(context, id) {
      return (new ApiHelper('get', apiRoutes.showPetWeight.replace('{id}', id)))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async storePetWeight(context, formData) {
      return (new ApiHelper('post', apiRoutes.storePetWeight, formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetWeightLoadingState')
        .handle();
    },

    async destroyPetWeight(context, id) {
      const formData = new FormData();
      formData.append('_method', 'DELETE');

      return (new ApiHelper('post', apiRoutes.destroyPetWeight.replace('{id}', id), formData))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async getPetHeight(context, id) {
      return (new ApiHelper('get', apiRoutes.showPetHeight.replace('{id}', id)))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async storePetHeight(context, formData) {
      return (new ApiHelper('post', apiRoutes.storePetHeight, formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetHeightLoadingState')
        .handle();
    },

    async destroyPetHeight(context, id) {
      const formData = new FormData();
      formData.append('_method', 'DELETE');

      return (new ApiHelper('post', apiRoutes.destroyPetHeight.replace('{id}', id), formData))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async getPetNote(context, id) {
      return (new ApiHelper('get', apiRoutes.showPetNote.replace('{id}', id)))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async storePetNote(context, formData) {
      return (new ApiHelper('post', apiRoutes.storePetNote, formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetNoteLoadingState')
        .handle();
    },

    async updatePetNote(context, [id, formData]) {
      if (formData instanceof FormData) {
        formData.append('_method', 'PUT');
      } else {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        formData._method = 'PUT';
      }

      return (new ApiHelper('post', apiRoutes.updatePetNote.replace('{id}', id), formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetNoteLoadingState')
        .handle();
    },

    async destroyPetNote(context, id) {
      const formData = new FormData();
      formData.append('_method', 'DELETE');

      return (new ApiHelper('post', apiRoutes.destroyPetNote.replace('{id}', id), formData))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async getPetChecklist(context, id) {
      return (new ApiHelper('get', apiRoutes.showPetChecklist.replace('{id}', id)))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async storePetChecklist(context, formData) {
      return (new ApiHelper('post', apiRoutes.storePetChecklist, formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetChecklistLoadingState')
        .handle();
    },

    async updatePetChecklist(context, [id, formData]) {
      if (formData instanceof FormData) {
        formData.append('_method', 'PUT');
      } else {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        formData._method = 'PUT';
      }

      return (new ApiHelper('post', apiRoutes.updatePetChecklist.replace('{id}', id), formData))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setPetChecklistLoadingState')
        .handle();
    },

    async destroyPetChecklist(context, id) {
      const formData = new FormData();
      formData.append('_method', 'DELETE');

      return (new ApiHelper('post', apiRoutes.destroyPetChecklist.replace('{id}', id), formData))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },
  },
};
