<template>
  <div class="app-wrapper" ref="appWrapper">
    <main id="main" class="app-wrapper__router" ref="appWrapperRouter">
      <router-view v-slot="slotProps">
        <transition :name="transitionName" @after-enter="onAfterRouteEnter">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </main>

    <TabBar v-if="user" />

    <div
      :class="`app-wrapper__alert is-${flashMessage.level}`"
      v-if="flashMessage && flashMessage.message"
      role="alert"
    >
      {{ flashMessage.message }}
    </div>
  </div>
</template>

<script>
import * as Vue from 'vue';
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import http from './http';
import './styles/spacing-helpers.css';
import './styles/app.css';
import './styles/router-transitions.css';
import './styles/toast.css';
import './styles/form.css';
import TabBar from './components/TabBar.vue';

export default {
  components: {
    TabBar,
  },

  provide() {
    return {
      theme: Vue.computed(() => {
        return this.theme;
      }),
    };
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  data() {
    return {
      scrollPositions: {},
      transitionName: '',
    };
  },

  created() {
    this.loadUser();
    this.loadTheme();

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          if (this.$route.meta && this.$route.meta.auth) {
            this.$store.dispatch('logout');
            this.$router.push({ name: 'Login' });
          }
        }

        return Promise.reject(error);
      },
    );

    // Handler to restore scroll positions on navigating...
    this.$router.afterEach((to, from) => {
      // Store current scroll position for this route name
      this.scrollPositions[from.name] = document.documentElement.scrollTop;

      let scroll = 0;

      // Retrieve scroll position for the next route
      if (typeof this.scrollPositions[to.name] !== 'undefined') {
        scroll = this.scrollPositions[to.name];
      }

      // When the next route is rendered, restore the stored scroll position
      this.$nextTick(() => {
        document.documentElement.scrollTop = scroll;
      });
    });
  },

  mounted() {
    window.addEventListener('click', (e) => {
      if (e.target.tagName === 'A') {
        if (e.target.getAttribute('href').indexOf('smolke.nl')) {
          this.$gtm.trackEvent({
            event: 'smolke-click',
            label: window.location.href,
          });
        }
      }
    });
  },

  computed: {
    ...mapGetters([
      'flashMessage',
      'activePet',
      'user',
      'theme',
    ]),
  },

  methods: {
    async loadUser() {
      this.$store.commit('setLoadingState', true);

      try {
        // Try retrieving the user. If it doesn't return a 200 response
        // the user is probably not logged in. The interceptor above
        // will handle this. When the user is returned we're logged
        // in and can return to the feed...
        await this.$store.dispatch('fetchUser');

        if (this.$route.meta && this.$route.meta.onboarding === true) {
          this.$router.push({ name: 'Feed' });
        }
      } catch (err) {
        //
      }

      this.$store.commit('setLoadingState', false);
    },

    async loadTheme() {
      try {
        await this.$store.dispatch('fetchTheme');
      } catch (err) {
        //
      }
    },

    onAfterRouteEnter() {
      // Display: grid is a little buggy where the height of one screen
      // is kept after navigating. This causes really long pages. By
      // toggling the display property the height is recalculated.
      this.$refs.appWrapperRouter.style.display = 'flex';

      requestAnimationFrame(() => {
        this.$refs.appWrapperRouter.style.display = 'grid';
      });
    },
  },

  watch: {
    activePet() {
      this.$textInterpolate.setPetName(this.activePet.name);
      this.$textInterpolate.setPetType(this.activePet.pet_type_identifier);
    },

    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;

      this.transitionName = toDepth < fromDepth ? 'prev' : 'next';
    },
  },
};
</script>
