import http from '@/http';

class ApiHelper {
  constructor(method, url, data = null) {
    this.commit = null;
    this.dispatch = null;
    this.loadingStateCommitName = null;
    this.authenticationRequired = false;
    this.userFetchPrevented = false;

    this.method = method;
    this.url = url;
    this.data = data;
  }

  /**
   * @returns {Promise<*>}
   */
  async handle() {
    if (!this.dispatch) {
      throw new Error('dispatch is required in ApiHelper');
    }

    if (this.authenticationRequired) {
      await this.dispatch('fetchCsrf');
    }

    if (this.commit && this.loadingStateCommitName) {
      this.commit(this.loadingStateCommitName, 'loading');
    }

    let promise = null;

    if (this.method === 'get') {
      promise = http.get(this.url);
    } else if (this.method === 'post') {
      promise = http.post(this.url, this.data);
    }

    if (!promise) {
      throw new Error('Method is not supported');
    }

    try {
      const res = await promise;

      if (this.commit && this.loadingStateCommitName) {
        this.commit(this.loadingStateCommitName, 'loaded');
      }

      if (this.authenticationRequired && !this.userFetchPrevented) {
        this.dispatch('fetchUser');
      }

      return res;
    } catch (err) {
      if (this.commit && this.loadingStateCommitName) {
        this.commit(this.loadingStateCommitName, 'error');
      }

      throw err;
    }
  }

  /**
   * @returns {ApiHelper}
   */
  authenticated() {
    this.authenticationRequired = true;

    return this;
  }

  /**
   * @returns {ApiHelper}
   */
  preventUserFetch() {
    this.userFetchPrevented = true;

    return this;
  }

  /**
   * @param {Object} context
   * @returns {ApiHelper}
   */
  connectWithContext(context) {
    if (typeof context.dispatch === 'undefined' || typeof context.commit === 'undefined') {
      throw new Error('Invalid context object');
    }

    return this
      .connectDispatch(context.dispatch)
      .connectCommit(context.commit);
  }

  /**
   * @param {Function} dispatch
   * @returns {ApiHelper}
   */
  connectDispatch(dispatch) {
    this.dispatch = dispatch;

    return this;
  }

  /**
   * @param {Function} commit
   * @returns {ApiHelper}
   */
  connectCommit(commit) {
    this.commit = commit;

    return this;
  }

  /**
   * @param {string} commitName
   * @returns {ApiHelper}
   */
  loadingStateCommit(commitName) {
    this.loadingStateCommitName = commitName;

    return this;
  }
}

export default ApiHelper;
