import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    //
  },

  actions: {
    async loadAddress(context, [postalCode, houseNumber]) {
      const route = `${apiRoutes.address}?postal_code=${postalCode}&house_number=${houseNumber}&country_code=nl`;

      return http.get(route)
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
