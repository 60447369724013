import config from '../config';
import ApiHelper from '../helpers/ApiHelper';

const { apiRoutes } = config;

export default {
  state: {
    current: 'default',
  },

  getters: {
    theme: (state) => state.current,
  },

  mutations: {
    setTheme(state, theme) {
      state.current = theme;
    },
  },

  actions: {
    async fetchTheme(context) {
      return (new ApiHelper('get', apiRoutes.theme))
        .authenticated()
        .preventUserFetch()
        .connectWithContext(context)
        .handle()
        .then(({ data }) => {
          context.commit('setTheme', data);
        });
    },
  },
};
