// Very basis meta handler. Vue-Meta does not support Vue 3 at the moment
// of this writing...
export default (meta) => {
  const title = document.querySelector('title');
  const description = document.querySelector('meta[name="description"]');
  const robots = document.querySelector('meta[name="robots"]');

  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  const ogTitle = document.querySelector('meta[property="og:title"]');

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  const ogDescription = document.querySelector('meta[property="og:description"]');

  const twitterImage = document.querySelector('meta[name="twitter:image:src"]');
  const ogImage = document.querySelector('meta[property="og:image"]');

  const ogUrl = document.querySelector('meta[property="og:url"]');

  let metaTitle = 'Kwispelcoach';
  let metaImage = 'https://app.kwispelcoach.com/img/social-image.jpg';
  let metaUrl = window.location.href;

  if (typeof meta.title !== 'undefined' && meta.title) {
    metaTitle = `${meta.title} - Smølke`;
  }

  if (typeof meta.image !== 'undefined' && meta.image) {
    metaImage = meta.image;
  }

  if (typeof meta.url !== 'undefined' && meta.url) {
    metaUrl = meta.url;
  }

  title.textContent = metaTitle;
  twitterTitle.setAttribute('content', metaTitle);
  ogTitle.setAttribute('content', metaTitle);

  description.setAttribute('content', typeof meta.description !== 'undefined' ? meta.description : '');
  twitterDescription.setAttribute('content', typeof meta.description !== 'undefined' ? meta.description : '');
  ogDescription.setAttribute('content', typeof meta.description !== 'undefined' ? meta.description : '');

  robots.setAttribute('content', typeof meta.robots !== 'undefined' ? meta.robots : 'index, follow');

  ogImage.setAttribute('content', metaImage);
  twitterImage.setAttribute('content', metaImage);

  ogUrl.setAttribute('content', metaUrl);
};
