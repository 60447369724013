import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    activeArticle: null,
    articleLoadingState: 'idle',
  },

  getters: {
    activeArticle: (state) => state.activeArticle,
  },

  mutations: {
    setArticleLoadingState(state, loadingState) {
      state.articleLoadingState = loadingState;
    },

    setActiveArticle(state, data) {
      state.activeArticle = data;
    },
  },

  actions: {
    async loadArticle({ commit }, slug) {
      let route = apiRoutes.article;

      route = route.replace('{slug}', slug);

      commit('setArticleLoadingState', 'loading');

      return http.get(route)
        .then(({ data }) => {
          commit('setActiveArticle', data);
          commit('setArticleLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setArticleLoadingState', 'error');
          throw err;
        });
    },
  },
};
