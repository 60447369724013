import config from '../config';
import ApiHelper from '../helpers/ApiHelper';

const { apiRoutes } = config;

export default {
  state: {
    milestones: [],
    activeMilestone: null,
    milestonesLoadingState: 'idle',
    markMilestoneCompleteLoadingState: 'idle',
    markMilestoneSkippedLoadingState: 'idle',
  },

  getters: {
    activeMilestone: (state) => state.activeMilestone,
    milestones: (state) => state.milestones,
    milestonesLoadingState: (state) => state.milestonesLoadingState,
    markMilestoneCompleteLoadingState: (state) => state.markMilestoneCompleteLoadingState,
    markMilestoneSkippedLoadingState: (state) => state.markMilestoneSkippedLoadingState,
  },

  mutations: {
    setMilestonesLoadingState(state, loadingState) {
      state.milestonesLoadingState = loadingState;
    },

    setMarkMilestoneCompleteLoadingState(state, loadingState) {
      state.markMilestoneCompleteLoadingState = loadingState;
    },

    setMarkMilestoneSkippedLoadingState(state, loadingState) {
      state.markMilestoneSkippedLoadingState = loadingState;
    },

    setMilestones(state, milestones) {
      state.milestones = milestones;
    },

    setActiveMilestone(state, data) {
      state.activeMilestone = data;
    },

    setActiveMilestoneBySlug(state, slug) {
      state.activeMilestone = state.milestones.find((milestone) => (
        milestone.translation.slug === slug
      ));
    },
  },

  actions: {
    async loadMilestones(context) {
      return (new ApiHelper('get', apiRoutes.milestones))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setMilestonesLoadingState')
        .handle()
        .then(({ data }) => {
          context.commit('setMilestones', data);
        });
    },

    async markMilestoneComplete(context, milestoneId) {
      return (new ApiHelper('post', apiRoutes.markMilestoneComplete, {
        milestone_id: milestoneId,
      }))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setMarkMilestoneCompleteLoadingState')
        .handle();
    },

    async markMilestoneSkipped(context, milestoneId) {
      return (new ApiHelper('post', apiRoutes.markMilestoneSkipped, {
        milestone_id: milestoneId,
      }))
        .authenticated()
        .connectWithContext(context)
        .loadingStateCommit('setMarkMilestoneSkippedLoadingState')
        .handle();
    },

    async updateMilestoneComplete(context, [milestoneId, formData]) {
      formData.append('_method', 'PUT');
      formData.append('milestone_id', milestoneId);

      return (new ApiHelper('post', apiRoutes.updateMilestoneComlete, formData))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },

    async updateChecklist(context, [milestoneId, checklistItems]) {
      return (new ApiHelper('post', apiRoutes.updateMilestoneChecklist, {
        _method: 'PUT',
        milestone_id: milestoneId,
        checklist_items: checklistItems,
      }))
        .authenticated()
        .connectWithContext(context)
        .handle();
    },
  },
};
