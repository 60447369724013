<template>
  <component :is="componentType" :to="to" :type="buttonType" :class="classNames">
    <slot></slot>
    <img
      v-if="showArrow && type === 'primary'"
      src="../assets/arrow-right-white.svg"
      alt=""
      aria-hidden="true"
    >
    <img
      v-if="showArrow && type !== 'primary'"
      src="../assets/arrow-right-orange.svg"
      alt=""
      aria-hidden="true"
    >
  </component>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: 'button',
    },

    classes: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'primary',
    },

    componentType: {
      type: String,
      default: 'button',
    },

    to: {
      type: [String, Object],
    },

    showArrow: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classNames() {
      return `${this.classes} btn btn--${this.type}`;
    },
  },
};
</script>

<style>
.btn {
  --button-color: #f06b00;
  --button-border-color: #f06b00;
  --button-background-color: #fff;
  --button-hover-color: #fff;
  --button-hover-background-color: #fa8a2c;
  --button-font-family: var(--default-font-family);
  --button-focus-box-shadow: 0 0 0 2px rgba(240, 107, 0, .3);
  --button-active-box-shadow: 0 0 0 2px rgba(240, 107, 0, .3);
  --button-disabled-opacity: 0.5;
  --button-padding: .75rem 1.25rem;
  --button-font-size: 1rem;
  --button-border-radius: 2rem;

  display: inline-block;
  font-family: var(--button-font-family);
  font-weight: 700;
  line-height: 1;
  color: var(--button-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  padding: var(--button-padding);
  font-size: var(--button-font-size);
  border-radius: var(--button-border-radius);
  transition: background-color .2s ease-out, color .2s ease-out;
}

.btn:hover {
  color: var(--button-hover-color);
  background-color: var(--button-hover-background-color);
}

.btn:focus {
  outline: none;
  box-shadow: var(--button-focus-box-shadow);
}

.btn:active {
  box-shadow: var(--button-focus-box-shadow);
}

.btn[disabled] {
  pointer-events: none;
  opacity: var(--button-disabled-opacity);
}

.btn--primary {
  --button-color: #fff;
  --button-background-color: #f06b00;
}

.btn--full-width {
  width: 100%;
}
</style>
