import config from '../config';
import http from '../http';

const { apiRoutes } = config;

export default {
  state: {
    searchQuery: null,
    searchResults: [],
    searchLoadingState: 'idle',
  },

  getters: {
    searchQuery: (state) => state.searchQuery,
    searchResults: (state) => state.searchResults,
    searchLoadingState: (state) => state.searchLoadingState,
  },

  mutations: {
    setSearchLoadingState(state, loadingState) {
      state.searchLoadingState = loadingState;
    },

    setSearchResults(state, results) {
      state.searchResults = results;
    },

    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
  },

  actions: {
    async search({ commit }, searchQuery) {
      commit('setSearchLoadingState', 'loading');

      return http.post(apiRoutes.search, {
        q: searchQuery,
      })
        .then(({ data }) => {
          commit('setSearchResults', data);
          commit('setSearchLoadingState', 'loaded');
        })
        .catch((err) => {
          commit('setSearchLoadingState', 'error');
          throw err;
        });
    },
  },
};
