import config from '../config';
import ApiHelper from '../helpers/ApiHelper';

const { apiRoutes } = config;

export default {
  state: {
    timelineLoadingState: 'idle',
    timeline: [],
  },

  getters: {
    timelineLoadingState: (state) => state.timelineLoadingState,
    timeline: (state) => state.timeline,
  },

  mutations: {
    setTimelineLoadingState(state, loadingState) {
      state.timelineLoadingState = loadingState;
    },

    setTimeline(state, timeline) {
      state.timeline = timeline;
    },
  },

  actions: {
    async loadTimeline(context) {
      return (new ApiHelper('get', apiRoutes.showTimeline))
        .authenticated()
        .connectWithContext(context)
        .handle()
        .then((res) => {
          context.commit('setTimeline', res.data.items);
        });
    },
  },
};
