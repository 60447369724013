<template>
  <onboarding-layout>
    <img
      class="logo"
      src="../assets/brand.svg"
      alt="Smølke logo"
    >

    <h1 class="heading sr-only">
      Kwispelcoach
    </h1>

    <img
      class="kwispelcoach-logo"
      src="../assets/kwispelcoach.svg"
      alt="Kwispelcoach logo"
    >

    <ul class="checked-list mb-3">
      <li>Dagelijks tips voor jouw pup of kitten</li>
      <li>Hou mijlpalen en foto’s bij</li>
      <li>Word beste vrienden met jouw maatje</li>
    </ul>

    <ul class="buttons-list">
      <li>
        <Button
          component-type="router-link"
          :to="{ name: 'OnboardingPetType' }"
          :show-arrow="false"
        >
          Start met jouw huisdier
        </Button>
      </li>

      <li>
        <Button
          component-type="router-link"
          to="/login"
          type="default"
          :show-arrow="false"
        >
          Ik heb al een account
        </Button>
      </li>
    </ul>
  </onboarding-layout>
</template>

<script>
import OnboardingLayout from './Onboarding/OnboardingLayout.vue';
import Button from '../components/Button.vue';

export default {
  name: 'Home',

  components: {
    OnboardingLayout,
    Button,
    //
  },

  created() {
    this.$store.commit('resetLayout');
  },
};
</script>

<style>
.buttons-list li:not(:last-child) {
  margin-bottom: .875rem;
}

.checked-list {
  text-align: left;
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}

.checked-list li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: .25rem;
}

.checked-list li:before {
  content: '';
  background: url(../assets/check.svg) no-repeat center center;
  background-size: 100% auto;
  position: absolute;
  top: .125rem;
  left: 0;
  width: 1rem;
  height: 1rem;
}

.logo {
  position: absolute;
  top: -.875rem;
  left: 50%;
  transform: translateX(-50%);
}

.kwispelcoach-logo {
  max-width: 12rem;
  margin-top: -1rem;
  margin-left: -.5rem;
}
</style>
